import React, { Component, createRef } from 'react';

import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Card from '../../Components/Card';
import Reservation from '../../Components/Reservation/Reservation';

import ParkingCategory from '../../constants/parkTypes';
import objectTypes from '../../constants/objectTypes';
import { PARKING_OPERATOR } from '../../Config';

import UIStore from '../../Stores/UIStore';
import ProfileParkingStore from '../../Stores/ProfileParkingStore';
import PathStore from '../../Stores/PathStore';
import UserStore from '../../Stores/UserStore';
import ReservationStore from '../../Stores/ReservationStore';
import SettingsStore from '../../Stores/SettingsStore';

import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

export class ReservationContainer extends Component {
  static defaultProps = {
    t: () => null
  };

  constructor() {
    super();

    this.reservation = createRef();
  }

  componentWillUnmount() {
    UIStore.reservationVisibility = false;
  }

  renderPayButton = () => {
    const { t, parking } = this.props;

    const isForDisabled = parking.category === ParkingCategory.FORDISABLED;
    const isFree = parking.category === ParkingCategory.FREE;
    const isParkomat = parking.objecttype === objectTypes.PARKOMATS;
    const isTerminal = parking.objecttype === objectTypes.TERMINALS;
    const isInformationTable = parking.objecttype === objectTypes.INFORMATIONTABLE;
    const isChurch = parking.objecttype === objectTypes.CHURCH;
    const isMuseum = parking.objecttype === objectTypes.MUSEUM;
    const isSight = parking.objecttype === objectTypes.SIGHT;1
    const isResidentZone = parking.objecttype === ParkingCategory.RESIDENTZONE;

    const isPlanar = parking.category === ParkingCategory.PLANAR;

    const className = 'btn-pay center-btn drp-btn btn-small-round btn-danger';

    if (!isFree && !isForDisabled && !isParkomat && !isTerminal && !isInformationTable && !isChurch && !isMuseum && !isSight && !isResidentZone) {
      const parkingPrices = parking.zone && parking.zone.prices ? parking.zone.prices : [];
      const currentPrice = ReservationStore.getTimesAndPrices(parkingPrices).currentPrice;

      // показывать кнопку оплатить всегда
      //const isButtonDisabled = isPlanar ? false : currentPrice === ReservationStore.FREE;
      const isButtonDisabled = false;

      let textBtn = isButtonDisabled
        ? t('reservation:parking:free')
        : t('reservation:parking:startParking');

      textBtn = isPlanar ? t('reservation:parking:payParking') : textBtn;

      return (
        <div className="reservation-pay">
          <Button
            onClick={this.handlePaymentClick}
            color="danger"
            type="button"
            className={className}
            disabled={isButtonDisabled}
          >
            {' '}
            {textBtn}{' '}
          </Button>{' '}
        </div>
      );
    }

    return null;
  };

  render() {
    const isScheduleHidden = ParkingCategory.FORDISABLED === this.props.parking.category || ParkingCategory.RESIDENTZONE === this.props.parking.objecttype;

    return UIStore.reservationVisibility ? (
      <div className="reservation__container">
        <Card className={'reservation'} withShadow={false}>
          <Reservation
            parking={this.props.parking}
            handleClose={this.handleClose}
            UIStore={this.props.UIStore}
            hideSchedule={isScheduleHidden}
            parkingOperator={PARKING_OPERATOR}
          />{' '}
        </Card>{' '}
        {this.renderPayButton()}{' '}
      </div>
    ) : null;
  }

  handleClose = () => {
    UIStore.toggleVisibilityReservation();
    ProfileParkingStore.setDefaultZoneNumber(null);
  };

  getPaymentUrl() {
    const paymentUrl = !UserStore.isLoggedIn()
      ? PathStore.pathWithLang('/guest-payment')
      : PathStore.pathWithLang('/profile/parking');

    return paymentUrl;
  }

  handlePaymentClick = () => {
    const { history } = this.props;

    const { zone, code, category } = this.props.parking;
    const zoneNumber = zone.zonenumber;
    //const isPlanar = category === ParkingCategory.PLANAR;

    ProfileParkingStore.setDefaultZoneNumber(zoneNumber);
    ProfileParkingStore.parkingCode =  code;
    ProfileParkingStore.parkingType = category;

    if (UserStore.isLoggedIn() || SettingsStore.settings.useSessionWithoutRegister) {
      history.push(this.getPaymentUrl());
    } else {
      UIStore.toggleLoginModal();
    }
  };
}

const withObserver = observer(ReservationContainer);
const withRoute = withRouter(withObserver);
const withTransaltion = translate(['reservation'], {
  wait: true
})(withRoute);

export default withTransaltion;
