import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

const theme = createTheme({
    palette: {
        primary: {
            main: '#135e9e',
        },
        secondary: {
            main: '#1587c8',
        },
    },
});

export default responsiveFontSizes(theme);