import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "reactstrap";
import Select from "@material-ui/core/Select";
import {Input, ListItemText} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";


function ModalConfirm(props) {
    const {
        isOpen = false,
        onClose = () => {},
        onConfirm = () => {},
        title,
        description,
    } = props;



    return (
        <Modal
            className="edit-profile-modal"
            size="md"
            isOpen={isOpen}
            onExit={onClose}
        >
            { title &&
            <ModalHeader>
                <span>{title}</span>
            </ModalHeader>}
            <ModalBody>
                <div>{description}</div>
            </ModalBody>
            <ModalFooter>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-st",
                        width: "100%"
                    }}
                >
                    <Button
                        color="gray"
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                        className="change-button"
                    >
                        Да
                    </Button>
                    <Button
                        color="gray"
                        onClick={onClose}
                        className="change-button"
                    >
                        Отмена
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ModalConfirm;

