import PARK_TYPES from '../../../../constants/parkTypes';

const iconUrls = {
  parking: {
    [PARK_TYPES.DEFAULT]: {
      green: '/static/media/icons/map/parking-icon-green.svg',
      yellow: '/static/media/icons/map/parking-icon-yellow.svg',
      red: '/static/media/icons/map/parking-icon-red.svg',
      default: '/static/media/icons/map/default.svg',
      orange: '/static/media/icons/map/parking-icon-orange.svg',
      black: '/static/media/icons/map/parking-icon-black.svg',
    },
    [PARK_TYPES.FREE]: {
      default: '/static/media/icons/map/default-free.svg',
      green: '/static/media/icons/map/default-free.svg',
      yellow: '/static/media/icons/map/default-free.svg',
      red: '/static/media/icons/map/default-free.svg',
      orange: '/static/media/icons/map/default-free.svg',
      black: '/static/media/icons/map/default-black.svg',
    },
    [PARK_TYPES.FORDISABLED]: {
      default: '/static/media/icons/map/default-for-disabled.svg',
      green: '/static/media/icons/map/for-disabled-green.svg',
      yellow: '/static/media/icons/map/for-disabled-yellow.svg',
      red: '/static/media/icons/map/for-disabled-red.svg',
      orange: '/static/media/icons/map/for-disabled-orange.svg',
      black: '/static/media/icons/map/for-disabled-black.svg',
    },
    [PARK_TYPES.RESIDENT]: {
      default: '/static/media/icons/map/default-resident.svg',
      green: '/static/media/icons/map/default-resident.svg',
      yellow: '/static/media/icons/map/default-resident.svg',
      red: '/static/media/icons/map/default-resident.svg',
      orange: '/static/media/icons/map/default-resident.svg',
      black: '/static/media/icons/map/default-resident.svg',
    },
    [PARK_TYPES.TAXI]: {
      default: '/static/media/icons/map/default-taxi.svg',
      green: '/static/media/icons/map/default-taxi.svg',
      yellow: '/static/media/icons/map/default-taxi.svg',
      red: '/static/media/icons/map/default-taxi.svg',
      orange: '/static/media/icons/map/default-taxi.svg',
      black: '/static/media/icons/map/default-taxi.svg',
    },
    [PARK_TYPES.BUS]: {
      default: '/static/media/icons/map/default-bus.svg',
      green: '/static/media/icons/map/default-bus.svg',
      yellow: '/static/media/icons/map/default-bus.svg',
      red: '/static/media/icons/map/default-bus.svg',
      orange: '/static/media/icons/map/default-bus.svg',
      black: '/static/media/icons/map/default-bus.svg',
    },
  },

  parkomat: {
    orange: '/static/media/icons/map/parkomat-orange.svg',
    default: '/static/media/icons/map/default-parkomat.svg',
  },
  terminal: {
    default: '/static/media/icons/map/default-terminal.svg',
    orange: '/static/media/icons/map/terminal-orange.svg',
  },
  informationtable: {
    default: '/static/media/icons/map/default-info.svg',
  },
  church: {
    default: '/static/media/icons/map/default-church.svg',
  },
  museum: {
    default: '/static/media/icons/map/default-museum.svg',
  },
  sight: {
    default: '/static/media/icons/map/default-sight.svg',
  },
};

export default iconUrls;
