import React from 'react';

import Title from './Title';
import Description from './Description';
import ExampleItemBr from './ExampleItemBr';
import text from './text';
import { CITY_NAME } from '../../Config';

function SectionAdd() {
  const city = CITY_NAME;
  return (
    <section className="update-page--add">
      <Title>Пополнение баланса:</Title>
      <Description className="example">
        <span className="black">{text[city].prefix}</span>*<span className="orange mg-l">pay</span>*
        <span className="blue">сумма пополнения</span>
      </Description>
      <Description>
        <p>Пример пополнения на 50 рублей</p>
        <div className="update-page__example">
          <ExampleItemBr>{text[city].prefix}</ExampleItemBr>*
          <ExampleItemBr color="orange">pay</ExampleItemBr>*
          <ExampleItemBr color="blue">50</ExampleItemBr>
        </div>
      </Description>
    </section>
  );
}

export default SectionAdd;
