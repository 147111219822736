import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "reactstrap";
import Select from "@material-ui/core/Select";
import {Input, ListItemText} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";


function ModalChangeTS(props) {
    const {
        isOpen = false,
        onClose = () => {},
        onChange = (orderNum, ts) => {},
        currentTs = '',
        listTs = [],
        orderNum,
    } = props;

    const [ts, setTs] = useState(currentTs);

    useEffect(() => {
        setTs(currentTs);
    }, [currentTs]);

    const onChangeTs = (event) => {
        setTs(event.target.value);
    }

    const getTsOptions = () => {
        return listTs.map((vehicle) => {
            return {
                label: vehicle,
                value: vehicle
            }
        })
    }


    return (
        <Modal
            className="edit-profile-modal"
            size="md"
            isOpen={isOpen}
            onExit={onClose}
        >
            <ModalHeader>
                <span>Изменить транспортное средство</span>
            </ModalHeader>
            <ModalBody>

                <div className="name">{'Транспортное средство'}</div>
                <div className="value">
                    <Select
                        fullWidth
                        value={ts}
                        onChange={onChangeTs}
                        input={<Input/>}
                        renderValue={(selected) => (
                            <div>
                                {selected}
                            </div>
                        )}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            },
                        }}
                    >
                        {getTsOptions().map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                <ListItemText primary={item.label}/>
                            </MenuItem>
                        ))}
                    </Select>
                </div>







            </ModalBody>
            <ModalFooter>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-st",
                        width: "100%"
                    }}
                >
                    <Button
                        color="gray"
                        onClick={() => {
                            onChange(orderNum, ts);
                            onClose();
                        }}
                        className="change-button"
                    >
                        Сохранить
                    </Button>
                    <Button
                        color="gray"
                        onClick={onClose}
                        className="change-button"
                    >
                        Отмена
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ModalChangeTS;

