import React, {Component} from 'react';
import {observer} from "mobx-react";
import {observable} from "mobx";
import SubscriptionConstructorStore from "../../../Stores/SubscriptionConstructorStore";
import './styles.css';
import {PageWrapper} from "../../../Components/ProfileComponents/Common";
import SubscriptionConstructorParams from "./SubscriptionConstructorParams/SubscriptionConstructorParams";
import UserStore from "../../../Stores/UserStore";
import {Grid} from "@material-ui/core";
import i18n from "../../../i18n/i18n";
import SubscriptionsContainer from "./SubsriptionsContainer";


/*

const SubscriptionConstructorStage = {
    selectParams: 'params',
    showAvailableSubscriptions: 'subscriptions',
}
*/


@observer
class SubscriptionConstructor extends Component {

    @observable
    searchParams = {
        zone: [],
        timing: null,
        vehicle: null,
        startDate: null
    };

    @observable
    selectedZones = [];

    @observable
    selectedTimings = null;

    @observable
    selectedVehicle = null;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        SubscriptionConstructorStore.init();
    }

    onSearchSubscriptions = (timing, zone, vehicle, startDate) => {
        this.searchParams.zone = zone;
        this.searchParams.timing = timing;
        this.searchParams.vehicle = vehicle;
        this.searchParams.startDate = startDate;
        console.log('Search params:', {...this.searchParams, zone: [...this.searchParams.zone]});
        SubscriptionConstructorStore.selectSubscriptions(this.searchParams.zone, this.searchParams.startDate, this.searchParams.timing).then((r => console.log(r)));
    }
    resetSubscriptions() {
        SubscriptionConstructorStore.resetSelectedSubscriptions();
    }
    render() {

        const subscriptionStyles = {
            title: {
                fontSize: '0.8rem',
                lineHeight: '1.3',
            }
        }

        return <PageWrapper>
            {(SubscriptionConstructorStore.status.isLoading || UserStore.profile.isLoading) && <div>Loading...</div>}
            {SubscriptionConstructorStore.status.error.status &&
                <div>{SubscriptionConstructorStore.status.error.message}</div>}
            {SubscriptionConstructorStore.status.done &&
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <h3 className='title' style={{padding: '1rem 0 1rem 0'}}>Конструктор абонементов</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <SubscriptionConstructorParams
                                disabled={SubscriptionConstructorStore.status.isLoading || UserStore.profile.isLoading || SubscriptionConstructorStore.selectSubscriptionsStatus.isLoading}
                                zones={SubscriptionConstructorStore.zones}
                                timings={SubscriptionConstructorStore.timing}
                                vehicles={UserStore.getTs().map(ts => ts.numberTs)}
                                onSearch={this.onSearchSubscriptions}
                                onReset={this.resetSubscriptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          style={{minHeight: '200px', padding: '1rem 0 1rem 0'}}
                    >
                        <Grid item xs={12}>
                            <SubscriptionsContainer t={i18n.t.bind(i18n)} selectedTs={this.searchParams.vehicle}/>
                        </Grid>
{/*                        {isEmpty(SubscriptionConstructorStore.selectedSubscriptions)
                            ? <Grid item xs={6} style={{margin: '0 auto',textAlign: 'center' }}>
                                <Typography variant="subtitle1">Нет подходящих абонементов измените параметры и нажмите
                                    "далее"</Typography>
                            </Grid>
                            : <>
                                {[...SubscriptionConstructorStore.selectedSubscriptions].map((subscription, index) => {
                                    return <Grid item xs={4} key={`selected-subscription-${index}`}>
                                        <Card style={{ height:'100%'}}>
                                            <CardContent>
                                                <Typography variant="subtitle2"
                                                            gutterBottom>{subscription.permissionKind}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                })
                                }
                            </>
                        }*/}
                    </Grid>

                </>
            }
        </PageWrapper>
    }
}


export default SubscriptionConstructor;