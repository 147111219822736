const abonementsEng = {
  benefitsTitle: 'Privileges',
  subscriptionsTitle: 'Subscriptions',
  errors: {
    error: 'Error',
    loadingDataError: 'Error loading data',
    benefitsIsEmpty: 'No privileges',
    subscriptionsIsEmpty: 'No subscriptions'
  },
  tryAgain: 'Try again',
  tableLabels: {
    permissionType: 'Type of permit',
    zone: 'Zone',
    cadastralQuarter: 'The cadastral quarter',
    vehicle: 'Vehicle',
    discountPercentage: 'Discount rate',
    period: 'Period'
  },
  table: {
    start: 'Start',
    end: 'End'
  },
  coverage: 'Сoverage',
  abonementFor: 'Subscription for',
  abonementTo: 'Subscription to',
  buttons: {
    pause: 'Pause',
    continue: 'Continue',
    refund: 'Refund',
    changeGrz: 'Change Transport',
  }
};

const abonementsRu = {
  benefitsTitle: 'Льготы',
  subscriptionsTitle: 'Абонементы',
  errors: {
    error: 'Ошибка',
    loadingDataError: 'Произошла ошибка при загрузке данных',
    benefitsIsEmpty: 'У вас нет льгот',
    subscriptionsIsEmpty: 'У вас нет абонементов'
  },
  tryAgain: 'Попробовать еще раз',
  tableLabels: {
    permissionType: 'Вид разрешения',
    zone: 'Зона',
    cadastralQuarter: 'Кадастровый квартал',
    vehicle: 'ТС',
    discountPercentage: 'Процент скидки',
    period: 'Период'
  },
  table: {
    start: 'Начало',
    end: 'Конец'
  },
  coverage: 'Зоны действия',
  abonementFor: 'Абонемент за',
  abonementTo: 'Абонемент на',
  buttons: {
    pause: 'Приостановить',
    continue: 'Возобновить',
    refund: 'Вернуть деньги',
    changeGrz: 'Изменить ТС',
  }
};

export default {
  eng: abonementsEng,
  ru: abonementsRu
};
