const filterEng = {
  filter: 'Filter',
  parking: 'Parking',
  placesAll: 'Spaces',
  placesFree: 'Vacant spaces',
  categories: {
    ROADSIDE: 'On-street parking',
    MULTILEVEL: 'Multi-level parking',
    PLANAR: 'Off-street parking',
    SOCIAL: 'Social parking',
    HOTEL: 'Hotel',
    POCKET: 'Angle parking',
    PARKOMAT: 'Parking meters',
    QIWI: 'Qiwi-terminals',
    INFORMATIONTABLE: 'Information table',
    PAYMENTTERMINAL: 'Payment terminal',
    FREE: 'Free',
    COMMERCE: 'Paid',
    FORDISABLED: 'Handicapped parking',
    MUSEUM: 'Museum',
    SIGHT: 'Sight',
    CHURCH: 'Church',
    RESIDENT: 'Resident parking',
    TAXI: 'Taxi',
    BUS: 'Tour buses',
    RESIDENTZONE: 'Resident zone',
  },
  types: {
    public: 'Public',
    other: 'Others'
  }
};
const filterRu = {
  filter: 'Фильтр',
  parking: 'Парковок',
  placesAll: 'Всего мест',
  placesFree: 'Свободных мест',
  categories: {
    ROADSIDE: 'Придорожные',
    MULTILEVEL: 'Многоуровневая',
    PLANAR: 'Плоскостные',
    SOCIAL: 'Социальные',
    HOTEL: 'Гостиницы',
    POCKET: 'Карманы',
    PARKOMAT: 'Паркоматы',
    QIWI: 'Qiwi-терминалы',
    INFORMATIONTABLE: 'Информационные табло',
    PAYMENTTERMINAL: 'Терминал оплаты',
    FREE: 'Бесплатные',
    COMMERCE: 'Коммерческие',
    FORDISABLED: 'Для инвалидов',
    MUSEUM: 'Музеи',
    SIGHT: 'Достопримечательности',
    CHURCH: 'Церкви',
    RESIDENT: 'Для резидентов',
    TAXI: 'Для такси',
    BUS: 'Экскурсионные автобусы',
    RESIDENTZONE: 'Резидентные зоны',
  },
  types: {
    public: 'Тип парковки',
    other: 'Прочее'
  }
};

export default {
  eng: filterEng,
  ru: filterRu
};
