import React from 'react';

import { Button } from 'reactstrap';
import SubscriptionCost from './SubscriptionCost';

import './style.css';

function SubscriptionCard(props) {
  const { title, parkingPermit, parkingPermitCode, parkingPermitDesc, /*zones,*/ cost, onClick, parkingPermitType, startDate } = props;

  function handleClick() {
    onClick({ parkingPermitCode, parkingPermit, cost, parkingPermitType, startDate });
  }

  return (
    <div className="sc-subscription">
      <div className="sc-subscription__title">{title}</div>
      <SubscriptionCost cost={cost} />
      <div className="sc-subscription__descr">
          {/*
            <div className="abonement__zones">Зоны действия</div>
            <div className="abonement__zone">{zones}</div>
          */}
          { parkingPermitDesc && <div className="sc-subscription__descr--text">{parkingPermitDesc}</div>}
      </div>
      <Button className="sc-subscription__pay" color="primary" onClick={handleClick}>
        Оплатить
      </Button>
    </div>
  );
}

SubscriptionCard.defaultProps = {
  title: '',
  parkingPermit: '',
  parkingPermitCode: '',
  parkingPermitType: '',
  zone: 0,
  cost: 0
};

export default SubscriptionCard;
