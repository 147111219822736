import React, { Children, cloneElement } from "react";

function SubscriptionCardContainer(props) {
  const { empty } = props;
  const abonements = props.children;

  function renderAbonements() {
    return Children.map(abonements, (abonement, key) => {
      const clonedElement = cloneElement(abonement, {
        key: key
      });

      return clonedElement;
    });
  }

  const emptyClassName = empty ? "sc-subscription__container--empty" : "";

  return (
    <div className={`sc-subscription__container ${emptyClassName}`}>
      {renderAbonements()}
    </div>
  );
}

SubscriptionCardContainer.defatulProps = {
  empty: true
};

export default SubscriptionCardContainer;
