import { observable } from 'mobx';

import APIProvider from '../APIProvider';
import CityStore from '../CityStore';
import ProfileParkingStore from '../ProfileParkingStore';
import constants from './constants';

import i18n from 'i18next';

class SettingsStore {
  @observable
  status = {
    isLoading: false,
    done: false,
    error: false,
  };

  city = CityStore.nameCityForShow;

  settings = {
    intervals: {
      min: this.defaultMin,
      max: this.defaultMax,
      step: this.defaultStep,
    },
    useTsCategory: false,
    useFlatParking: false,
    usePostPayment: false,
    usePostPaymentYesterday: false,
    useSessionWithoutRegister: false,
    useBonus: false,
    viewPenalty: false,
    paymentByParkingId: false,
    paymentsMethods: [],
    dontUseBalance: false,
    topics: [],
    useSubscriptionConstructor: false,
  };

  defaultStep = 15;
  defaultMin = 15;
  defaultMax = 1440;

  getZoneOrParkingLabel() {
    return this.settings.paymentByParkingId
      ? i18n.t('parking:payments:labels:parking')
      : i18n.t('parking:payments:labels:zone');
  }

  getZoneOrParkingNumber(session) {
    return this.settings.paymentByParkingId
      ? `№ ${session.parkingCode}`
      : `# ${session.zone}`;
  }

  loadSettings = async () => {
    this.status.isLoading = true;
    if (this.city !== CityStore.nameCityForShow) {
      this.city = CityStore.nameCityForShow;

      const response = await APIProvider.getSettingsV3();
      if (response) {
        const normolizedSettings = this.normalizeSettingsV3(response);

        this.settings = normolizedSettings;

        this.status = {
          isLoading: false,
          done: true,
          error: false,
        };

        return normolizedSettings;
      } else {
        this.status = {
          isLoading: false,
          done: false,
          error: true,
        };
      }
    }
  };

  normalizeSettings(settings) {
    const methods = settings[constants.PAYMENTS_METHODS];
    const intervals = settings[constants.INTERVALS];

    return {
      intervals: this.normalizeIntervals(intervals),
      useTsCategory: settings[constants.USE_TS_CATEGORY],
      useFlatParking: settings[constants.USE_FLAT_PARKING],
      usePostPayment: settings[constants.USE_POST_PAYMENT],
      paymentByParkingId: settings[constants.PAYMENT_BY_PARKING_ID],
      paymentsMethods: this.normalizeMethods(methods),
    };
  }

  normalizeSettingsV3(settings) {
    return {
      ...this.normalizeSettings(settings),
      useSessionWithoutRegister:
        settings[constants.USE_SESSION_WITHOUT_REGISTER],
      dontUseBalance: settings[constants.DONT_USE_BALANCE],
      usePostPaymentYesterday: settings[constants.USE_POST_PAYMENT_YESTERDAY],
      topics: this.normalizeTopics(settings[constants.TOPICS]),
      useBonus: settings[constants.USE_BONUS],
      viewPenalty: settings[constants.VIEW_PENALTY],
      useReceipts: settings[constants.USE_RECEIPTS],
      useSubscriptionConstructor: settings[constants.USE_SUBSCRIPTION_CONSTRUCTOR],
    };
  }

  normalizeMethods(methods = []) {
    return methods.map((method) => ({
      methodName: method[constants.METHOD_NAME],
      methodNameForShow: method[constants.METHOD_NAME_FOR_SHOW],
      methodDescription: method[constants.METHOD_DESCRIPTION],
      isPhoneRequired: method[constants.IS_PHONE_REQUIRED],
    }));
  }

  normalizeTopics(topics) {
    if (topics) {
      return topics
        .map((topic) => ({
          label: topic[constants.TOPIC_NAME],
          value: topic[constants.TOPIC_CODE],
        }))
        .concat([{ label: 'Пусто', value: null }]);
    }

    return [];
  }

  normalizeIntervals(intervals = {}) {
    return {
      min: intervals[constants.MIN] || this.defaultMin,
      max: intervals[constants.MAX] || this.defaultMax,
      step: intervals[constants.STEP] || this.defaultStep,
    };
  }

  getTimeintervals = () => {
    const times = this.settings ? this.settings.intervals : null;

    if (times) {
      return ProfileParkingStore.timesWithInterval(
        times.step,
        times.max,
        times.min
      );
    }

    return ProfileParkingStore.timesWithInterval();
  };
}

export default new SettingsStore();
