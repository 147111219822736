import React, { Component } from 'react';
import Route from '../Routes/RootLngRoute';

import ProfileMenu from '../../Components/ProfileComponents/ProfileMenu';
import Parking from './Parking';
import OperationsContainer from './Operations/OperationsContainer';
import Abonements from './Abonements';
import Information from './Information';
import profileStore from '../../Stores/ProfileStore';
import UserStore from '../../Stores/UserStore';
import Penalty from './Penalty';
import FeedbackPage from './Feedback/FeedbackPage';

import '../../Assets/Styles/Profile/Compiled/all.css';
import { translate } from 'react-i18next';
import Receipts from "./Receipts/Receipts";
import { ReceiptsPage } from "../Loadable";
import PrivateRoute from "../Routes/PrivateRouter";
import SubscriptionConstructor from "./SubscriptionConstructor";

@translate(['header'], { wait: true })
export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.menuItems = profileStore.menuItems;
    this.state = {
      activeSection: 0,
    };

    if (this.props.match.path === this.props.location.pathname) {
      this.props.history.push(
        `/profile/${this.menuItems[this.state.activeSection].type}`
      );
    }
  }

  componentDidMount() {
    if (!UserStore.isLoggedIn()) {
      window.location.href = '/';
    }
  }

  UNSAFE_componentWillUpdate() {
    this.menuItems = profileStore.menuItems;
    if (this.props.match.path === this.props.location.pathname) {
      this.props.history.push(
        `/profile/${this.menuItems[this.state.activeSection].type}`
      );
    }
  }

  render() {
    return (
      <div
        className="container-fluid "
        style={{ padding: 0, flex: '1 0 auto', backgroundColor: '#eee' }}
      >
        <ProfileMenu
          section={this.state.activeSection}
          menuItems={this.menuItems}
          location={this.props.location.pathname}
        />

        <Route path={'/profile/parking'} component={Parking} />
        <Route path={'/profile/operations'} component={OperationsContainer} />
        <Route path={'/profile/abonements'} component={Abonements} />
        <Route path={'/profile/constructor'} component={SubscriptionConstructor} />
        <Route path={'/profile/my-profile'} component={Information} />
        <Route path={'/profile/penalty'} component={Penalty} />
        <Route path={'/profile/feedback'} component={FeedbackPage} />
        <Route path={'/profile/receipts'} component={Receipts} />

        <Route path={'/:lng/profile/parking'} component={Parking} />
        <Route
          path={'/:lng/profile/operations'}
          component={OperationsContainer}
        />
        <Route path={'/:lng/profile/abonements'} component={Abonements} />
        <Route path={'/:lng/profile/my-profile'} component={Information} />
        <Route path={'/:lng/profile/penalty'} component={Penalty} />
        <Route path={'/:lng/profile/feedback'} component={FeedbackPage} />
      </div>
    );
  }
}
