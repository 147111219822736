import {observable, action} from "mobx";
import APIProvider from "./APIProvider";
import isEmpty from "lodash/isEmpty";

class SubscriptionConstructorStore {

  @observable zones = [];
  @observable timing = [];

  @observable selectedSubscriptions = [];

  @observable status = {
    isLoading: false,
    done: false,
    error: {
      status: false,
      message: ""
    }
  };

  @observable selectSubscriptionsStatus = {
    isLoading: false,
    done: false,
    error: {
      status: false,
      message: ""
    }
  };

/*  loadDataIfNeeded() {
    if (this.abonements.length === 0) {
      this.loadSubscriptions();
    }
  }*/

  @action
  async init() {
      this.selectedSubscriptions = [];
      this.status.isLoading = true;
      const res = await Promise.all([this.loadZones(), this.loadTiming()]);
      this.status.isLoading = false;
      if (res[0] && res[1]) {
        this.status.done = true;
      } else {
        this.status.done = false;
        this.status.error.status = true;
        this.status.error.message = "Ошибка загрузки данных";
      }
  }

  @action
  async loadZones() {
    //this.status.isLoading = true;
    const result = await APIProvider.subscriptionConstructorGetZones();
    this.zones = result ? result.result : [];
    //this.status.isLoading = false;
    //this.done = true;
    return !isEmpty(this.zones);
  }


  @action
  async loadTiming() {
    //this.status.isLoading = true;
    const result = await APIProvider.subscriptionConstructorGetTiming();
    this.timing = result ? result.result : [];
    //this.status.isLoading = false;
    //this.done = true;
    return !isEmpty(this.timing)
  }

  @action
  async selectSubscriptions(zones, startDate, timing) {
    this.selectSubscriptionsStatus.isLoading = true;
    const result = await APIProvider.subscriptionConstructorSelect(zones, startDate, timing );
    this.selectedSubscriptions = result ? result.result : [];
    this.selectSubscriptionsStatus.isLoading = false;
    this.done = true;
    return !isEmpty(this.timing)
  }

  @action
  resetSelectedSubscriptions = () => {
    this.selectedSubscriptions = [];
  }
}

export default new SubscriptionConstructorStore();
